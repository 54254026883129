.container {
  font-family: "Google Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
}

.heading h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 4rem;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 88%;
}

.data-cards {
  display: flex;
  justify-content: space-between;
  /* gap: 2rem; */
  flex-wrap: wrap;
}

.note {
  background: #0f9d56;
  text-align: center;
  color: white;
  margin-top: 5rem;
  padding: 1.2rem 0;
  border-radius: 6px;
  font-size: 20px;
}

.marqueeAnimContainer {
  background: #076585; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fff,
    #076585
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fff,
    #076585
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-top: 4rem;
}

.marqueeAnim {
  margin-top: 3rem;
  font-family: "Google Sans";
  color: white;
  font-weight: 600;
  font-size: 25px;
  animation-name: marquee;
  animation-duration: 40s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  white-space: nowrap;
  position: relative;
}

.marqueeAnim:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  from {
    left: 0;
  }
  to {
    left: -8850px;
  }
}

.marqueeAnim > div {
  display: inline-flex;
}

.marqueeAnim > div > span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  height: 90px;
  background: red;
  padding: 30px;
}

@media screen and (max-width: 1250px) {
  .heading h1 {
    margin-bottom: 2rem;
    font-size: 28px;
  }
  .note {
    margin-top: 1rem;
  }
  .marqueeAnimContainer{
    margin-top: 0;
  }
}

@media screen and (max-width: 600px) {
  .note {
    margin-top: 4px;
    font-size: 14px;
    padding: 10px;
  }
  .data-cards {
    flex-direction: column;
    align-items: center;
  }
  .marqueeAnimContainer {
    overflow: hidden;
    background: transparent;
  }
  .marqueeAnim {
    margin-top: 1rem;
    font-size: 20px;
    animation-duration: 50s;
  }
  .marqueeAnim > div > span {
    height: 50px;
  }
  @keyframes marquee {
    from {
      left: 0;
    }
    to {
      left: -7420px;
    }
  }
}
