.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3a3a3a;
  text-align: center;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
}

.bg {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bg > img{
  width: 100%;
  height: 100%;
  text-align: center;
}

.avatar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10rem;
  left: 0;
  padding: 12px;
}

.avatar > img {
  width: 100%;
  height: 100%;
  text-align: center;
}

.name {
  margin-bottom: 12px;
}

.name > h2 {
  font-weight: 400;
  font-size: 22px;
}

.designation {
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  gap: 1.1rem;
}

.link > a {
  display: flex;
  justify-content: center;
  align-items: center;
   width: 35px;
  height: 35px;
  border-radius: 50%;
}

.social-links > div:nth-child(1) > a > svg {
  transform: scale(0.6);
}

.social-links > div:nth-child(2) > a > svg {
  transform: scale(1);
}


.link {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9e7e7;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.link:hover {
  transform: scale(1.1);
  background: #f6f4f4;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

@media screen and (max-width: 550px) {
  .name > h2 {
    font-size: 16px;
  }
  .avatar{
    padding: 8px;
  }
  .image {
    width: 120px;
    height: 120px;
  }
}
