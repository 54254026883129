.venueContainer {
  font-family: "Google Sans";
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5rem;
}

.heading h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  display: flex;
  margin-bottom: 3rem;
}

.container {
  display: flex;
  justify-content: center;
  width: 75%;
  gap: 3rem;
  max-height: 450px;
  min-width: 930px;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 40%;
  gap: 3rem;
}

.text {
  display: flex;
  align-items: center;
  transform: translate(3%, 0);
}

.locationText {
  font-weight: 700;
  margin-left: 1.5rem;
}

.locationText > p {
  font-size: 1.8rem;
  line-height: 2.2rem;
  max-width: 24ch;
}

.locationSvg > img {
  width: 30px;
}

.imageContainer {
  width: 60%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
}

.imageContainer > img {
  width: 100%;
  height: 100%;
}

.maps {
  min-height: 300px;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.maps > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .container {
    min-width: 0;
    max-height: none;
    width: 90%;
  }
  .locationText > p {
    max-width: none;
  }
  .heading h2 {
    margin-bottom: 2rem;
    font-size: 28px;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column-reverse;
    width: 85%;
    gap: 2rem;
    margin-bottom: 0;
  }
  .imageContainer {
    width: 100%;
  }
  .mapContainer {
    width: 100%;
    align-items: center;
    gap: 2rem;
  }
  .text {
    transform: none;
    justify-content: center;
    padding-left: 8%;
  }
  .locationText > p {
    font-size: 22px;
    line-height: normal;
    padding-right: 2rem;
  }
  .locationText > p > br {
    display: none;
  }
  .locationSvg > img {
    width: 27px;
  }
  .locationText {
    margin-left: 1rem;
  }
  .maps {
    width: 100%;
    min-height: 250px;
    background: red;
  }
}
