.container {
  width: 88%;
  font-family: "Google Sans";
  color: white;
  background-color: #0f9d56;
  margin: 2rem auto;
  padding: 1rem 3rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 5rem;
}

.container a{
  z-index: 100;
}

.heading-and-cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
}
.cta-btn {
  background: #202124;
  color: white;
  border-radius: 6.59991px;
  padding: 0.7rem 2rem;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
}
.heading {
  display: flex;
  gap: 7px;
  align-items: center;
}
.heading h2 {
  font-size: 31px;
}
.heading span > svg {
  width: 23px;
}
.early-bird {
  text-align: center;
  font-size: 25px;
  margin: 5px 0;
}
.perks {
  display: flex;
  gap: 1.5rem;
}
.perks > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.perks1,
.perks2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.perk {
  display: flex;
  align-items: center;
  gap: 5px;
}
.illustrations {
  display: flex;
  position: relative;
  top: 25px;
  align-items: flex-end;
}

@media screen and (max-width: 1175px) {
  .container {
    padding: 1rem 2rem;
    margin-top: 4rem;
  }
}
@media screen and (max-width: 1135px) {
  .container {
    padding: 1rem 1rem;
  }
}
@media screen and (max-width: 1135px) {
  .container {
    padding: 1rem 1rem;
  }
}
@media screen and (max-width: 1100px) {
  .heading h2 {
    font-size: 26px;
  }
  .illustrations {
    top: 53px;
  }
  .mmt-svg > svg {
    width: 75px;
  }
  .lion-svg > svg {
    width: 120px;
  }
}
@media screen and (max-width: 960px) {
  .container {
    flex-direction: column;
    padding: 1rem 3rem;
    padding-bottom: 0;
  }
  .heading-and-cta {
    gap: 0px;
  }
  .cta-btn {
    position: relative;
    top: 9rem;
    margin: 0 auto;
  }
  .illustrations {
    width: 100%;
    justify-content: space-between;
  }
  .mmt-svg > svg {
    width: 98px;
    position: relative;
    bottom: 2rem;
  }
  .perks {
    gap: 3.5rem;
  }
}
@media screen and (max-width: 585px) {
  .container {
    height: 29rem;
    margin-top: -1rem;
    width: 90%;
  }
  .perks {
    flex-direction: column;
    gap: normal;
    gap: 1rem;
  }
  .cta-btn {
    top: 14rem;
  }
  .illustrations {
    top: 30px;
  }
  .mmt-svg > svg {
    width: 69px;
  }
  .lion-svg > svg {
    width: 75px;
  }
}
@media screen and (max-width: 460px) {
  .illustrations {
    width: 125%;
  }
}
@media screen and (max-width: 390px) {
  .container {
    padding-top: 1rem;
  }
  .early-bird {
    font-size: 21px;
  }
  .heading h2 {
    font-size: 23px;
  }
}
@media screen and (max-width: 350px) {
  .container {
    padding: 0;
    padding-top: 1rem;
  }
  .illustrations {
    width: 100%;
  }
}
