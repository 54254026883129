:root {
  --google-gray: #202124;
  --google-blue: #4285f4;
}

.menu {
  display: flex;
  font-family: "Google Sans";
  align-items: center;
  box-shadow: 0px 4px 4px 0px #2b34441f;
  justify-content: center;
  position: relative;
  height: 65px;
  width: 100%;
  position: fixed;
  z-index: 1000;
}

.content {
  display: flex;
  width: 88%;
  justify-content: space-between;
  padding: 10px 0;
}

.devfest2022 {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.devfest-icon {
  padding-right: 8px;
}

.devfest-icon > svg {
  width: 175px;
  height: 30px;
  margin-top: 9px;
}

.nagpur-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  position: relative;
}

.nagpur-theme::after {
  position: absolute;
  content: "";
  height: 70%;
  background: #dedede;
  width: 2px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.nagpur-theme span:first-child {
  font-weight: 700;
  font-size: 24px;
}

.orange-svg {
  margin-left: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  display: flex;
}

.navigation > ul {
  display: flex;
  list-style-type: none;
  padding-right: 1.5rem;
}

.menu-item {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
}

.menu-item > a {
  padding: 6px 1rem;
  transition: all 0.2s ease;
  border-radius: 30px;
  font-size: 16px;
}

.menu-item > a:hover {
  background: #e7ecec;
  color: black !important;
}

.menu-item > .active{
  background: var(--google-blue) !important;
  color: white !important;
}

.menu-item > .active:hover{
  background: var(--google-blue) !important;
  color: white !important;
}

.menu-item > a {
  text-decoration: none;
}

.menu-item > a:visited{
  color: initial;
}

/* 
  ==============
  toggle styles
  ==============
  */
.toggle {
  display: flex;
  align-items: center;
  font-family: "Google Sans";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mode {
  margin-right: 10px;
  font-size: 16px;
}

.toggle button {
  background: none;
  border: none;
  width: 22px;
  height: 22px;
  display: flex;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle button:hover {
  opacity: 0.7;
}

.toggle .moon {
  width: 16px;
  height: 16px;
}

.toggle button svg {
  width: 100%;
  height: 100%;
}

.toggle button svg path {
  fill: rgba(244, 180, 0, 1);
}

.toggle .moon path {
  fill: #ffffff;
}

@media screen and (max-width: 1300px) {
  .content {
    width: 92%;
  }
  .mode {
    display: none;
  }
  .navigation ul{
    padding-right: 1rem;
  }
  .menu-item{
    margin: 0 4px;
  }
  .devfest-icon > svg {
    width: 140px;
    height: 25px;
    margin-top: 9px;
  }
  .nagpur-theme {
    display: flex;
    align-items: flex-end;
    padding-left: 12px;
    position: relative;
  }
  .nagpur-theme span:first-child {
    font-weight: 700;
    font-size: 20px;
  }
}

.hamburger-btn {
  display: none;
}

@media screen and (max-width: 1250px) {
  .menu {
    justify-content: center;
    flex-direction: column;
  }
  .content {
    justify-content: center;
  }
  .navigation {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 1s ease;
    width: 50vw;
    height: 90vh;
    max-height: 400px;
    max-width: 200px;
    background: red;
    transform: translate(-100%, 0);
    z-index: 100;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 10px 0px;
  }
  .navigation.nav-open {
    transform: translate(0, 0);
  }
  .navigation > ul {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    width: 100%;
    padding-left: 2rem;
  }
  .navigation > ul > li {
    margin-bottom: 10px;
    /* justify-content: flex-start; */
  }
  /* hamburger menu */
  .hamburger-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    left: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border-radius: 3px;
    z-index: 10000;
  }
  .hamburger {
    position: relative;
    width: 26px;
    height: 1.8px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    background: black;
  }
  .hamburger::after,
  .hamburger::before {
    content: "";
    position: absolute;
    width: 26px;
    height: 1.8px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
    background: black;
  }
  .hamburger.dark::after,
  .hamburger.dark::before {
    background: white;
  }
  .hamburger::after {
    transform: translateY(8px);
  }
  .hamburger::before {
    transform: translateY(-8px);
  }
  .hamburger.dark {
    background: white;
  }
  .open .hamburger {
    transform: translateX(-8px);
    box-shadow: none;
    background: transparent;
  }
  .open .hamburger::after {
    transform: rotate(-45deg) translate(5px, 5px);
  }
  .open .hamburger::before {
    transform: rotate(45deg) translate(5px, -5px);
  }
  .mode {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .devfest-icon > svg {
    width: 110px;
    margin-top: 9px;
  }
  .nagpur-theme {
    display: flex;
    align-items: flex-end;
    padding-left: 10px;
    position: relative;
  }
  .nagpur-theme span:first-child {
    font-weight: 700;
    font-size: 17px;
  }
  .nagpur-theme::after {
    width: 1.5px;
  }
  .orange-svg {
    margin-left: 5px;
  }
  .orange-svg > svg {
    height: 18px;
  }
  .toggle {
    right: 4%;
    width: 20px;
    height: 20px;
  }
}
