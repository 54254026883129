.container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Google Sans";
    /* padding: 8rem 0; */
    flex-direction: column;
}

.heading{
    margin-bottom: 2rem;
}

.heading > h1{
    font-size: 28px;
}

.content{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error{
    margin-top: 1rem;
    color: red;
}

.form{
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.field{
    display: flex;
    flex-direction: column;
}

.field > label{
    margin-bottom: 10px;
    opacity: 0.9;
}

.field > label > span > a{
    color: #00ab47;
}


.field > input{
    padding: 8px;
    border: 1px solid #abc3ea;
    border-radius: 3px;
    transition: all 0.3s ease;
    box-shadow: rgba(78, 75, 75, 0.1) 0px 1px 3px 0px;
}

.field > input:focus{
    outline: none;
    border: 1px solid #4285f4;
}

.form button{
    margin-top: 1rem;
    padding: 8px;
    font-size: 15px;
    text-transform: uppercase;
    background: #4285f4;
    border-radius: 3px;
    color: white;
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.form button:hover{
    opacity: 0.9;
}

@media screen and (max-width: 600px){
    .heading{
        text-align: center;
    }
    .heading > h1{
        font-size: 1.3rem;
        padding: 0 1rem;
    }
    .field > label > span{
        display: inline-block;
        margin-bottom: 6px;
    }
}   