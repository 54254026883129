.email-social {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: "Google Sans";
  /* border-top: 1px solid rgba(0, 0, 0, 1); */
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  align-items: center;
  min-height: 100px;
  padding: 0 6%;
}
.community-guidlines > a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  text-decoration: underline;
}
.email-social::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.email-social.dark::after {
  background: rgba(255, 255, 255, 0.25);
}

.email {
  transform: translate(0, -12%);
  display: flex;
}

.email p {
  font-size: 18px;
  line-height: 22px;
  margin-right: 10px;
}

.email a {
  color: rgba(66, 133, 244, 1);
  font-weight: 600;
  font-size: 18px;
  font-family: "Google Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -1px);
}

.social {
  display: flex;
  align-items: center;
}

.social p {
  font-size: 17px;
}

.social > div {
  display: flex;
}

.social a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 1rem;
  transition: transform 0.2s ease;
}

.social a:hover {
  transform: scale(1.2) translate(0, -3px);
}

.light-link {
  background: #f1f1f1;
}

.dark-link {
  background: #4a4b4d;
}

.social > div > a > svg {
  width: 45%;
}

.dark-link > svg > path {
  fill: #e8eaed;
}

/* media queries for smaller screens */

@media screen and (max-width: 1000px) {
  .email-social {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 0;
  }
}

@media screen and (max-width: 900px) {
  .email-social {
    min-height: auto;
    padding: 1rem 8%;
    text-align: center;
  }
  .email-social::after {
    width: 88%;
    transform: translate(-50%, 0);
    left: 50%;
  }
  .email {
    flex-direction: column;
    margin-bottom: 0rem;
  }
  .email span {
    display: none;
  }
  .email a {
    transform: translate(0, 0);
    font-size: 16px;
  }
  .social {
    flex-direction: column;
  }
  .social p {
    margin-bottom: 8px;
  }
  .social a {
    margin-left: 0rem;
    margin: 0 8px;
  }
}
