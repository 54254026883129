.slider-container-light,.slider-container-dark {
  width: 100%;
  height: 20rem;
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Google Sans";
  padding: 2rem 1rem;
}
.slider-container-dark{
  background: #4a4b4d;
}
.slide-pic > img {
  width: 68%;
}
.slide-info {
  width: 50%;
}
.slide-info > p {
  font-size: 27px;
  font-weight: 600;
}
.role {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  font-weight: 600;
}

/* Media queries  */
@media screen and (max-width: 800px) {
  .slide-info > p {
    font-size: 21px;
  }
}
@media screen and (max-width: 665px) {
  .slide-info > p {
    font-size: 18px;
  }
  .slide-info {
    width: 70%;
  }
  .role span {
    font-size: 14px;
  }
}
@media screen and (max-width: 550px) {
  .slide-info > p {
    font-size: 15px;
  }
  .slide-info {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .slider-container-light,.slider-container-dark {
    height: 24rem;
  }
  .slider-container-light,.slider-container-dark {
    flex-direction: column;
    padding-bottom: 2rem;
  }
  .slide-pic > img {
    width: 100%;
  }
  .slide-pic {
    width: 50%;
  }
  .slide-info > p {
    font-size: 15px;
  }
  .slide-info {
    width: 85%;
    text-align: center;
  }
}
@media screen and (max-width: 350px) {
  .slide-pic {
    width: 65%;
  }
  .slide-info > p {
    font-size: 14px;
  }
}
