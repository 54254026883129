.container {
  background: #202124;
  display: flex;
  flex-direction: column;
  height: 550px;
  /* background: red !important; */
  /* border-bottom: 1px solid white; */
  border-top: 1px solid white;
  overflow: hidden;
}

.cloudContainer {
  position: absolute;
  right: 0;
}

.cloud3 {
  position: relative;
  bottom: 13px;
}

.heading {
  color: white;
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  position: relative;
  left: 6%;
  top: 55px;
  width: 88%;
}

.devfestBodyContainer {
  display: flex;
  position: relative;
  top: 20%;
  left: 6%;
  width: 80%;
}

.imgContainer {
  position: relative;
  z-index: 9;
}

.svgContainer {
  position: relative;
  bottom: 290px;
  left: 20px;
}

.bodyText {
  position: relative;
  left: 9.5%;
  top: 7%;
  width: 58%;
  height: auto;
  color: white;
  font-family: "Google Sans";
  font-style: normal;
  font-size: 20px;
  line-height: 25px;
}

.bottomContainer {
  position: relative;
  width:35%;
  left: 65%;
  bottom: 216px;
  display: flex;
  justify-content:space-around;
  align-items:baseline;
  transform: translate(0, -3px);
}

@media screen and (max-width: 1100px) {
  .container {
    height:auto;
  }
  .headingWrapper{
    height:130px;
  }
  .cloudContainer {
    position: absolute;
    bottom:0.01px;
    right: 0;
  }
  .devfestBodyContainer{
    flex-direction: column-reverse;
    justify-content:space-between;
  }
  .image {
    width:100%;
    top:10%;
    max-height: 300px;
  }
  .imgWrapper{
    margin-top:30px;
    margin-left:25%;
  }
  .bodyText{
    left: 0;
    width: 100%;
  }
  .bottomContainer {
    position: relative;
    width:100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content:space-around;
    align-items:baseline;
  }
}

@media screen and (max-width: 700px) {
  .headingWrapper{
    height: 100px;
  }
  .heading{
    font-size: 18px;
  }
  .image {
    width:100%;
    height:300px;
    top:10%;
  }
  .imgWrapper{
    margin-left:20%;
  }
  .imgContainer>img{
    width:300px;
  }
  .svgContainer>svg{
    width:300px;
  }
  .svgContainer {
    position: relative;
    bottom: 255px;
    left: 12px;
  }
  .bodyText{
    font-size: 16px;
  }
  .bottomContainer {
    margin-bottom:-13px;
    transform:scale(0.81);
  }
}

@media screen and (max-width: 500px) {
  .imgWrapper{
    margin-left:0;
    height: 200px;
  }
  .bottomContainer {
    justify-content:space-between;
  }
}