.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Google Sans";
    padding-bottom: 6rem;
}

.heading{
    padding: 3rem 0;
}

.heading > h1{
    color: #3F3F3F;
    font-size: 2.25rem;
}

.content{
    width: 88%;
    display: grid;
    /* grid-template-columns: repeat(5, 1fr); */
    grid-template-columns: repeat(5, auto);
    gap: 2rem;
    max-width: 1250px;
}


@media screen and (max-width: 1100px){
    .content{
        grid-template-columns: repeat(3, minmax(auto, 1fr));
    }
}

@media screen and (max-width: 800px){
    .content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        
    }
}

@media screen and (max-width: 550px){
    .heading{
        padding-top: 2rem;
        padding-bottom: 2.5rem;
    }
}