@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap");

@font-face {
  font-family: "Google Sans";
  src: url("./fonts/GoogleSansBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Google Sans";
  src: url("./fonts/GoogleSansRegular.ttf");
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  background: #fbfbfb;
  overflow-x: hidden;
   -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
}
.dark {
    background: rgba(32, 33, 36, 1);
    color: white;
}
.faq-row:last-child,.faq-title{
    border: none!important;
}
.faq-title h2{
    color: rgb(80, 80, 80)!important;
}

.layout-content{
  padding-top: 65px;
}

:target {
    scroll-margin-top: 65px;
}