.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Google Sans";
  margin-bottom: 2rem;
}

.heading h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  display: flex;
  margin-bottom: 2rem;
}

.content {
  width: 88%;
  border-radius: 8px;
  /* border: 1px solid #0f9d56; */
  display: flex;
}

.tweets {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 1rem;
  min-height: 500px;
}

.tweet {
  width: 30%;
}

/* .tweet :global(.twitter-tweet){
  border-radius: 15px;
} */

@media screen and (max-width: 1200px) {
  .tweet {
    width: auto;
  }
  .heading h2 {
    margin-bottom: 2rem;
    font-size: 28px;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 400px) {
  .content {
    border-radius: 5px;
    padding: 1rem 0;
  }
  .tweet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
  }
  .tweet > div{
    width: 100%;
  }
}
