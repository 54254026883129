.container {
  padding-top: 5rem;
  padding-bottom: 1rem;
}

.heading {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #505050;
  text-align: center;
  margin-bottom: 2rem;
}

.themesBody {
  position: relative;
  height: 350px;
}

.bgimg1 {
  position: absolute;
  width: 88%;
  height: 100%;
  left: 6%;
}

.bgimg2 {
  display: none;
}

.textContainer {
  position: relative;
  top: 44%;
  display: flex;
  justify-content: space-around;
}

.techImg {
  margin-left: 15%;
}
.startupImg {
  margin-right: 13%;
}

@media screen and (max-width: 1250px) {
  .heading {
    font-size: 28px;
  }
}

@media screen and (max-width: 1100px) {
  .themesBody {
    margin-top: 20px;
    min-height: 500px;
  }
  .bgimg1 {
    display: none;
  }
  .bgimg2 {
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    left: 25%;
  }
  .textContainer {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    top: 0;
  }
  .techImg {
    margin-left: 0px;
    transform: scale(0.7);
  }
  .startupImg {
    margin-right: 0px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 800px) {
  .themesBody {
    height: 720px;
  }
  .bgimg2 {
    display: block;
    position: absolute;
    width: 70%;
    height: 100%;
    left: 15%;
  }
}

@media screen and (max-width: 450px) {
  .container{
    padding-top: 2.5rem;
  }
  .heading{
    margin-bottom: 1rem;
  }
  .themesBody {
    max-height: 90vh;
  }
  .textContainer{
    transform: translate(0, -5%);
  }
  .techImg > svg{
    transform: scale(0.6);
  }
  .startupImg > svg{
    transform: scale(0.6) translate(0, -50%);
  }
  .bgimg2 {
    display: block;
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
  }
}
